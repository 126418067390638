// accessible autocomplete. keyboard friendly & screenreader friendly.
// for now used for sales sites search. reusable for other types of search.

import accessibleAutocomplete from 'accessible-autocomplete';

interface SuggestedSalesSiteDocument {
    title: string,
    coordinates: string,
    postalCode: number
}

interface AutoSuggestResponse<T> {
    suggestions: T[],
    errorMessage: string,
    status: number
}

export class AccessibleAutocomplete {
    fetchSuggestions (this: { uri: string, searchType: string }, query: string, populateResults: (s: SuggestedSalesSiteDocument[]) => void): void {
        fetch(this.uri + query, {
            method: 'GET'
        })
            .then((response): Promise<AutoSuggestResponse<undefined>> => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.status.toString() + ' ' + response.statusText);
                }
            })
            .then((response: AutoSuggestResponse<undefined>) => {
                if (response.errorMessage === '' && response.suggestions) {
                    if (this.searchType === 'sales-sites') {
                        const suggestions = (response.suggestions as unknown) as SuggestedSalesSiteDocument[];
                        populateResults(suggestions);
                    }
                } else {
                    throw new Error(response.errorMessage);
                }
                return null;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    autocompleteByType (searchType: string): void {
        const autocompleteContainer = (document.getElementById(searchType + '-autocomplete-container') as HTMLDivElement);
        const formElement = (document.getElementById(searchType + '-search-form') as HTMLFormElement);
        const originalInputElement = (document.getElementById(searchType + '-input') as HTMLInputElement);

        if (autocompleteContainer && formElement) {
            const endpoint = formElement.dataset.suggest || '';
            const queryPart = formElement.dataset.queryPart || '';
            const autocompleteParameters = {
                searchType,
                uri: endpoint + '?' + queryPart
            };
            const options: { [key: string]: unknown } = {
                element: autocompleteContainer,
                id: searchType + '-input',
                defaultValue: originalInputElement.value,
                name: originalInputElement.name,
                placeholder: window.localizationLabels.salesSitesSearchFormPlaceholder,
                showNoOptionsFound: true,
                autoselect: true,
                displayMenu: 'overlay',
                source: this.fetchSuggestions.bind(autocompleteParameters),
                onConfirm: (confirmed: SuggestedSalesSiteDocument) => {
                    if (confirmed) {
                        if (searchType === 'sales-sites') {
                            const autoCreatedInputElement = (document.getElementById(searchType + '-input') as HTMLInputElement);
                            autoCreatedInputElement.setAttribute('data-coordinates', confirmed.coordinates);
                            // submit
                            const submitEvent = new CustomEvent('submit', { bubbles: true, cancelable: true });
                            formElement.dispatchEvent(submitEvent);
                        }
                    }
                },
                confirmOnBlur: false,
                tAssistiveHint: () => window.localizationLabels.salesSitesSearchFormAriaDescribedBy,
                tNoResults: () => window.localizationLabels.salesSitesSearchFormNoResults,
                tStatusNoResults: () => window.localizationLabels.salesSitesSearchFormNoResults,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                tStatusSelectedOption: (selectedOption: string, length: number, index: number) => '' // too annoying: `${selectedOption} ${index + 1} von ${length} ist hervorgehoben.`
            };
            if (searchType === 'sales-sites') {
                options.templates = {
                    inputValue: this.inputValueTemplateSalesSites,
                    suggestion: this.suggestionTemplateSalesSites
                };
            }
            // Remove original input field before autocomplete init, as that adds its own
            originalInputElement.remove();
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            accessibleAutocomplete(options);
            const autoCreatedInputElement = (document.getElementById(searchType + '-input') as HTMLInputElement);
            autoCreatedInputElement.type = 'search';
        }
    }

    inputValueTemplateSalesSites = (result: SuggestedSalesSiteDocument): string => {
        if (typeof result === 'object') {
            return result && result.title + ' (' + String(result.postalCode) + ')';
        }
        return '';
    };

    suggestionTemplateSalesSites = (result: SuggestedSalesSiteDocument): string => {
        if (typeof result === 'object') {
            return result && '<span data-coordinates-suggest="' + result.coordinates + '">' + result.title
                + ' (' + String(result.postalCode) + ')' + '</span>';
        }
        return '';
    };
}
