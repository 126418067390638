const defaultOptions: PositionOptions = {
    enableHighAccuracy: false,
    timeout: 5000, // milliseconds
    maximumAge: 5 * 60 * 1000 // milliseconds
};

function getCurrentPosition (options?: PositionOptions): Promise<GeolocationPosition> {
    if (!window.currentPositionPromise) {
        window.currentPositionPromise = new Promise(
            (resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject, options);
            }
        );
    }
    return window.currentPositionPromise;
}

function getGeolocationPosition (options?: PositionOptions): Promise<GeolocationPosition> {
    if (navigator.geolocation) {
        return getCurrentPosition(options || defaultOptions);
    } else {
        throw Error('No support for geolocation API.');
    }
}

export default getGeolocationPosition;
