// eslint-disable-next-line import/no-unassigned-import, import/no-relative-parent-imports
import '../../scss/modules-extra/sales-sites.scss';
import getGeolocationPosition from './geolocation';
import { AccessibleAutocomplete } from './accessible-autocomplete';

let searchCoordinates: string | undefined;

function renderResults (response: SalesSitesResponse): void {
    const searchResults = <HTMLElement>document.querySelector('.sales-sites-results');
    searchResults.innerHTML = '';
    if (response.nResults > 0) {
        const searchResultsList = document.createElement('UL');
        const mapMarkerData: GoogleMapsMarkerList = [];
        response.salesSiteData.forEach((elem) => {
            const resultsItem = document.createElement('LI');
            let resultsItemContent = `<strong>${elem.title}</strong>`;
            let address = '';
            if (elem.addressAdditional) {
                address += `${elem.addressAdditional}, `;
            }
            address += `${elem.addressMain}, ${elem.postalCode} ${elem.city} `;
            resultsItemContent += `<p>${address}</p>`;
            resultsItemContent += `<a href="${elem.url}" class="btn btn-outline-black">${window.localizationLabels.toDetailPage}</a>`;
            resultsItem.innerHTML = resultsItemContent;
            searchResultsList.appendChild(resultsItem);

            mapMarkerData.push({
                title: elem.title,
                info: `<a href="${elem.url}"><strong>${elem.title}</strong></a><p>${elem.addressMain}<br>${elem.postalCode} ${elem.city}</p>`,
                lat: +elem.coordinates.split(',')[0],
                lon: +elem.coordinates.split(',')[1]
            });
        });
        searchResults.appendChild(searchResultsList);

        if (window.ucDps.googleMaps) {
            window.googleMapsController.setMarkerIconColor('#ffc356');
            window.googleMapsController.showMultiMarkerMap('mapContainer', mapMarkerData, 0, 0);
        }
    }
}

function getSalesSitesSearchResults (data: FormData): void {
    if (data.get('tx_solr[q]') === '') {
        data.delete('tx_solr[q]');
    }
    if (searchCoordinates !== undefined) {
        data.delete('tx_solr[q]');
        data.append('coordinates', searchCoordinates);
    }

    fetch('?type=7382', {
        method: 'POST',
        body: data
    }).then((response): Promise<SalesSitesResponse> => {
        if (response.status === 200) {
            return response.json();
        } else {
            throw new Error(response.status.toString() + ' ' + response.statusText);
        }
    }).then((response: SalesSitesResponse) => {
        const searchMessage = <HTMLElement>document.querySelector('.search-message');
        if (searchMessage) {
            // Empty aria-live region first to ensure that the new amount of sales sites is announced, even if it is equal to the previous amount:
            searchMessage.textContent = '';
            searchMessage.classList.add('alert', 'alert-secondary');
            if (response.nResults === 0) {
                // aria-live region might have troubles if we change innerHtml => it's more reliable to change textContent:
                searchMessage.textContent = window.localizationLabels.noResults;
            } else if (response.nResults === 1) {
                searchMessage.textContent = window.localizationLabels.resultInfoText;
            } else {
                searchMessage.textContent = window.localizationLabels.resultsInfoText.replace('%1$s', response.nResults.toString());
            }

            renderResults(response);
        }

        return null;
    }).catch((error) => {
        console.error(error);
    });
}

function useGeoLocation (formData: FormData): void {
    getGeolocationPosition()
        // eslint-disable-next-line promise/always-return
        .then((position) => {
            searchCoordinates = `${position.coords.latitude},${position.coords.longitude}`;
            const locationMessage = <HTMLElement>document.querySelector('.location-message');
            const searchInput = <HTMLInputElement>document.getElementById('sales-sites-input');
            // eslint-disable-next-line promise/always-return
            if (locationMessage && searchInput) {
                // Empty aria-live region first to ensure that the new label is announced, even if it is equal to the previous label:
                locationMessage.textContent = '';
                // locationMessage.textContent = window.localizationLabels.salesSitesInMyLocation;
                searchInput.value = '';
                searchInput.removeAttribute('data-coordinates');
            }
            getSalesSitesSearchResults(formData);
        })
        .catch((error) => {
            const locationMessage = <HTMLElement>document.querySelector('.location-message');
            if (locationMessage) {
                // Empty aria-live region first to ensure that the new label is announced, even if it is equal to the previous label:
                locationMessage.textContent = '';
                locationMessage.textContent = window.localizationLabels.inactiveGeolocation;
                locationMessage.classList.add('alert', 'alert-warning');
            }
            console.error(error);
        });
}

function workOnDom (): void {
    const searchForm = document.querySelector<HTMLFormElement>('.sales-sites-search-form');
    if (searchForm) {
        searchForm.addEventListener('submit', function (event) {
            event.preventDefault();
            const searchInput = <HTMLInputElement>document.getElementById('sales-sites-input');
            searchCoordinates = searchInput.getAttribute('data-coordinates') as string;
            getSalesSitesSearchResults(new FormData(searchForm));
        });

        const useGeolocationButton = <HTMLButtonElement>document.querySelector('.use-geolocation');
        if (useGeolocationButton) {
            useGeolocationButton.addEventListener('click', function () {
                useGeoLocation(new FormData(searchForm));
            });
        }

        document.getElementById('sales-sites-input')?.addEventListener('focus', (event: Event): void => {
            if (!(<HTMLInputElement>event.target).value) {
                searchCoordinates = undefined;
                const locationMessage = <HTMLElement>document.querySelector('.location-message');
                // eslint-disable-next-line promise/always-return
                if (locationMessage) {
                    // Empty aria-live region first to ensure that the new label is announced, even if it is equal to the previous label:
                    locationMessage.textContent = '';
                    locationMessage.classList.remove('alert', 'alert-secondary');
                }
            }
        });
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => workOnDom());
} else {
    workOnDom();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const autocomplete = new AccessibleAutocomplete();
    autocomplete.autocompleteByType('sales-sites');
}
